import {
  AcceptInvite,
  DeclineInvite,
  GetPendingInvite,
  GetTeamStatus,
  GetUserInvites,
  InviteToTeam,
  LeaveTeam,
  MemberStatus,
  PublicInvite,
  RemoveUserFromTeam,
  RenameTeam,
  RevokeInvite,
} from "../../serviceClient/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../common/commonTypes";
import { TeamMembersState } from "./../../serviceClient/api.dtos";

type TeamsState = {
  teamStatus: TeamMembersState;
  teamStatusLoading: Loading;
  teamNameLoading: Loading;
  teamInviteLoading: Loading;
  userTeamInvitesLoading: Loading;
  rejectInviteLoading: Loading;
  approveInviteLoading: Loading;
  pendingInvitesLoading: Loading;
  rescindInviteLoading: Loading;
  removeMemberLoading: Loading;
  removeMemberModalVisibility: boolean;
  selectedMember?: MemberStatus;
  inviteModalVisibility: boolean;
  pendingInvite?: PublicInvite;
};

const initialState: TeamsState = {
  approveInviteLoading: "idle",
  inviteModalVisibility: false,
  pendingInvite: new PublicInvite(),
  pendingInvitesLoading: "idle",
  rejectInviteLoading: "idle",
  removeMemberLoading: "idle",
  removeMemberModalVisibility: false,
  rescindInviteLoading: "idle",
  selectedMember: new MemberStatus(),
  teamInviteLoading: "idle",
  teamNameLoading: "idle",
  teamStatus: new TeamMembersState(),
  teamStatusLoading: "idle",
  userTeamInvitesLoading: "idle",
};

export const fetchTeamStatus = createAsyncThunk(
  "teams/fetchTeamStatus",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .get(new GetTeamStatus())
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const setTeamName = createAsyncThunk(
  "teams/setTeamName",
  async ({ name }: RenameTeam, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .put(new RenameTeam({ name }))
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const sendTeamInvite = createAsyncThunk(
  "teams/fetchTeamInvite",
  async (receiverEmail: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .post(new InviteToTeam({ receiverEmail }))
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchPendingInvite = createAsyncThunk(
  "teams/fetchPendingInvite",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .get(new GetPendingInvite())
        .then((status) => {
          if (status.id) {
            thunkAPI.dispatch(setInviteModalVisibility(true));
          }
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchUserInvites = createAsyncThunk(
  "teams/fetchUserInvites",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .get(new GetUserInvites())
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const rejectInvite = createAsyncThunk(
  "teams/rejectInvite",
  async (inviteId: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .delete(new DeclineInvite({ inviteId }))
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const approveInvite = createAsyncThunk(
  "teams/approveInvite",
  async (inviteId: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .put(new AcceptInvite({ inviteId }))
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const rescindInvite = createAsyncThunk(
  "teams/rescindInvite",
  async (inviteId: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .delete(new RevokeInvite({ inviteId }))
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const quitTeam = createAsyncThunk(
  "teams/quitTeam",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .post(new LeaveTeam())
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const removeUserFromTeam = createAsyncThunk(
  "teams/removeUserFromTeam",
  async (userId: string, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .delete(new RemoveUserFromTeam({ userId }))
        .then((status) => {
          return status;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const nestsSlice = createSlice({
  initialState: initialState,
  name: "teamsSlice",
  reducers: {
    setRemoveMemberModalVisibility: (state, action) => {
      state.removeMemberModalVisibility = action.payload;
    },
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setInviteModalVisibility: (state, action) => {
      state.inviteModalVisibility = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET: Fetch team status
    builder
      .addCase(fetchTeamStatus.pending, (state) => {
        state.teamStatusLoading = "pending";
      })
      .addCase(fetchTeamStatus.fulfilled, (state, action) => {
        state.teamStatusLoading = "succeeded";
        state.teamStatus = action.payload as TeamMembersState;
      })
      .addCase(fetchTeamStatus.rejected, (state) => {
        state.teamStatusLoading = "failed";
      });

    // PUT: Rename team
    builder
      .addCase(setTeamName.pending, (state) => {
        state.teamNameLoading = "pending";
      })
      .addCase(setTeamName.fulfilled, (state) => {
        state.teamNameLoading = "succeeded";
      })
      .addCase(setTeamName.rejected, (state) => {
        state.teamNameLoading = "failed";
      });

    // POST: Send team invite
    builder
      .addCase(sendTeamInvite.pending, (state) => {
        state.teamInviteLoading = "pending";
      })
      .addCase(sendTeamInvite.fulfilled, (state, action) => {
        state.teamInviteLoading = "succeeded";
        state.teamStatus = action.payload as TeamMembersState;
      })
      .addCase(sendTeamInvite.rejected, (state) => {
        state.teamInviteLoading = "failed";
      });

    // GET: Fetch user invites
    builder
      .addCase(fetchUserInvites.pending, (state) => {
        state.userTeamInvitesLoading = "pending";
      })
      .addCase(fetchUserInvites.fulfilled, (state) => {
        state.userTeamInvitesLoading = "succeeded";
      })
      .addCase(fetchUserInvites.rejected, (state) => {
        state.userTeamInvitesLoading = "failed";
      });

    // DELETE: Reject invite
    builder
      .addCase(rejectInvite.pending, (state) => {
        state.rejectInviteLoading = "pending";
      })
      .addCase(rejectInvite.fulfilled, (state) => {
        state.rejectInviteLoading = "succeeded";
      })
      .addCase(rejectInvite.rejected, (state) => {
        state.rejectInviteLoading = "failed";
      });

    // PUT: Approve invite
    builder
      .addCase(approveInvite.pending, (state) => {
        state.approveInviteLoading = "pending";
      })
      .addCase(approveInvite.fulfilled, (state) => {
        state.approveInviteLoading = "succeeded";
      })
      .addCase(approveInvite.rejected, (state) => {
        state.approveInviteLoading = "failed";
      });

    // GET: Fetch pending invites
    builder
      .addCase(fetchPendingInvite.pending, (state) => {
        state.pendingInvitesLoading = "pending";
      })
      .addCase(fetchPendingInvite.fulfilled, (state, action) => {
        state.pendingInvitesLoading = "succeeded";
        state.pendingInvite = action.payload;
      })
      .addCase(fetchPendingInvite.rejected, (state) => {
        state.pendingInvitesLoading = "failed";
      });

    // DELETE: Rescind invite
    builder
      .addCase(rescindInvite.pending, (state) => {
        state.rescindInviteLoading = "pending";
      })
      .addCase(rescindInvite.fulfilled, (state, action) => {
        state.rescindInviteLoading = "succeeded";
        state.teamStatus = action.payload as TeamMembersState;
      })
      .addCase(rescindInvite.rejected, (state) => {
        state.rescindInviteLoading = "failed";
      });

    // POST: Quit team
    builder
      .addCase(quitTeam.pending, (state) => {
        state.rescindInviteLoading = "pending";
      })
      .addCase(quitTeam.fulfilled, (state) => {
        state.rescindInviteLoading = "succeeded";
      })
      .addCase(quitTeam.rejected, (state) => {
        state.rescindInviteLoading = "failed";
      });

    // DELETE: Remove user from team
    builder
      .addCase(removeUserFromTeam.pending, (state) => {
        state.removeMemberLoading = "pending";
      })
      .addCase(removeUserFromTeam.fulfilled, (state, action) => {
        state.removeMemberLoading = "succeeded";
        state.teamStatus = action.payload as TeamMembersState;
      })
      .addCase(removeUserFromTeam.rejected, (state) => {
        state.removeMemberLoading = "failed";
      });
  },
});

export const {
  setInviteModalVisibility,
  setRemoveMemberModalVisibility,
  setSelectedMember,
} = nestsSlice.actions;

export default nestsSlice.reducer;
