import * as signalR from "@microsoft/signalr";

import {
  ReportProgressState,
  exportRequest,
  setReportProgress,
} from "../../features/nesting/nestingSlice";
import { getTokenRedirect, guid } from "../../Util";

import { AppDispatch } from "../../app/store";
import { ExportType } from "../api.dtos";
import { IServerEvents } from "./IServerEvents";
import { PublicClientApplication } from "@azure/msal-browser";
import { addToastMessage } from "../../features/toastMessages/toastMessagesSlice";
import { getServerEventHandlers } from "./ServerEventHandlers";
import { t } from "i18next";

const reportEvents = ["ExportGenerated", "ExportFailed"];
const serverEventURI = "/exportHub";

export default class ServerEventsReport implements IServerEvents {
  protected _sessionId: string;
  private _type: ExportType;

  public constructor() {
    this.initialiseConnection();
  }

  public connectionIsLive(): boolean {
    return this._hubConnection.state === "Connected";
  }

  public dispose() {
    return this._hubConnection.stop();
  }

  public setType(type: ExportType) {
    this._type = type;
  }

  public setDispatch(dispatch: AppDispatch) {
    this._dispatch = dispatch;
  }

  public setGetState(getState: any) {
    this._getState = getState;
  }

  public async setSessionId(sessionId: string) {
    this._sessionId = sessionId;

    await this.resetChannels();
  }

  public set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  public set getState(getState: any) {
    this._getState = getState;
  }

  // Setter for getState
  public set dispatch(dispatch: AppDispatch) {
    this._dispatch = dispatch;
  }

  private _dispatch: AppDispatch;
  private _getState: () => void;

  private _hubConnection = new signalR.HubConnectionBuilder()
    .configureLogging(
      process.env.REACT_APP_BUILD_TYPE === "production"
        ? signalR.LogLevel.Warning
        : signalR.LogLevel.Debug
    )
    .withUrl(process.env.REACT_APP_API_BASE_URL + serverEventURI, {
      accessTokenFactory: async () => {
        let token: string = "";
        await getTokenRedirect(window.MsalInstance as PublicClientApplication)
          .then((response) => {
            if (response) {
              token = response.token ?? "";
            }
          })
          .catch(() => "");
        return token;
      },

      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();

  private _startSignalRConnection = async () => {
    const dispatch = this._dispatch;

    dispatch(setReportProgress(ReportProgressState.Pending));

    const hub = this._hubConnection;

    await hub
      .start()
      .then(async () => {
        console.debug("Reports SignalR Connection Started  ", this._sessionId);
        dispatch(setReportProgress(ReportProgressState.Connected));

        await hub
          .invoke("ListenForUpdates", this._sessionId)
          .then(() => {
            console.debug(
              "Reports SignalR Connection Established  ",
              this._sessionId
            );
            dispatch(setReportProgress(ReportProgressState.Active));
            dispatch(exportRequest(this._type))
              .unwrap()
              .then(() => {})
              .catch((error) => {
                dispatch(setReportProgress(ReportProgressState.Idle));
                //  could return limitationReachedPleaseUpgradeAccountExceptionExport as the old exportNestingSession did?

                let errorMessage = t("toastMessages.exportFileError");
                errorMessage += " - " + error?.responseStatus?.message;

                if (this.connectionIsLive()) {
                  this.dispose().then(() => {
                    dispatch(
                      addToastMessage({
                        id: guid(),
                        severity: "danger",
                        text: errorMessage,
                      })
                    );
                  });

                  dispatch(setReportProgress(ReportProgressState.Idle));
                }
              });
          })
          .catch(function (err) {
            dispatch(setReportProgress(ReportProgressState.Idle));
            return console.debug("SignalR ", err.toString());
          });
      })
      .catch((err) => {
        console.error("Nesting SignalR Connection Error: ", err);
        dispatch(setReportProgress(ReportProgressState.Idle));

        dispatch(
          addToastMessage({
            id: guid(),
            severity: "danger",
            text: t("toastMessages.exportFileError"),
          })
        );
        if (hub.state !== signalR.HubConnectionState.Disconnected) {
          setTimeout(() => {
            hub.invoke("ListenForUpdates", this._sessionId);
          }, 3000);
        }
      });

    return hub;
    //
  };

  public triggerEvent(event: { eventName: string; params: object }) {
    getServerEventHandlers(this._dispatch, this._getState)[event.eventName](
      event.params
    );
  }

  protected _onConnection() {
    reportEvents.map((event) => {
      return this._hubConnection.on(event, (model: any) => {
        this.triggerEvent({
          eventName: event,
          params: model,
        });
      });
    });
  }
  protected _onCloseConnection() {
    // re-establish the connection if connection dropped

    this._hubConnection.onclose(() => setTimeout(this._onConnection, 5000));
  }

  protected async initialiseConnection(): Promise<void> {
    console.debug("Initialising Reports SignalR Connection");
    this._onConnection();
    if (this._sessionId) {
      this._onCloseConnection();
    }
  }

  private async resetChannels(): Promise<void> {
    if (this._hubConnection.state !== "Disconnected") {
      await this._hubConnection.stop();
    }

    if (this._sessionId) {
      this._startSignalRConnection();
    }
  }
}
