import {
  FeedbackResponse,
  FeedbackSubmission,
  SendToSupport,
} from "../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./../common/commonTypes";

type FeedbackState = {
  feedbackError: string;
  feedbackLoading: Loading;
  feedbackResponse: FeedbackResponse;
  feedbackModalVisibility: boolean;
  sendToSupportCheckbox: boolean;
  sendToSupportCheckboxLoading: Loading;
  sendToSupportCheckboxError: string;
};

const initialState: FeedbackState = {
  feedbackError: "",
  feedbackLoading: "idle",
  feedbackResponse: new FeedbackResponse(),
  feedbackModalVisibility: false,
  sendToSupportCheckbox: false,
  sendToSupportCheckboxError: "",
  sendToSupportCheckboxLoading: "idle",
};

export const sendFeedback = createAsyncThunk(
  "/sendFeedback",
  async (
    { feedback, feedbackType, screenShot }: FeedbackSubmission,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(
          new FeedbackSubmission({
            currentUri: window.location.toString(),
            feedback,
            feedbackType,
            screenShot,
          })
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const sendToSupport = createAsyncThunk(
  "/sendToSupport",
  async ({ projectId, reason }: SendToSupport, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(
          new SendToSupport({
            projectId,
            reason,
          })
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const feedbackSlice = createSlice({
  initialState,
  name: "feedbackSlice",
  reducers: {
    setFeedbackModalVisibility(state, action: PayloadAction<boolean>) {
      state.feedbackModalVisibility = action.payload;
    },
    setSendToSupportCheckbox(state, action: PayloadAction<boolean>) {
      state.sendToSupportCheckbox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // =======================================================================
      // POST: Send Feedback
      .addCase(sendFeedback.pending, (state) => {
        state.feedbackLoading = "pending";
        state.feedbackError = "";
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.feedbackResponse = action.payload as FeedbackResponse;
        state.feedbackLoading = "succeeded";
        state.feedbackError = "";
      })
      .addCase(sendFeedback.rejected, (state, rejectedAction) => {
        state.feedbackLoading = "failed";
        state.feedbackError = rejectedAction.payload as string;
      })
      // =======================================================================
      // POST: Send Feedback
      .addCase(sendToSupport.pending, (state) => {
        state.sendToSupportCheckboxLoading = "pending";
        state.sendToSupportCheckboxError = "";
      })
      .addCase(sendToSupport.fulfilled, (state) => {
        state.sendToSupportCheckboxLoading = "succeeded";
        state.sendToSupportCheckboxError = "";
      })
      .addCase(sendToSupport.rejected, (state, rejectedAction) => {
        state.sendToSupportCheckboxLoading = "failed";
        state.sendToSupportCheckboxError = rejectedAction.payload as string;
      });
  },
});

export const { setFeedbackModalVisibility, setSendToSupportCheckbox } =
  feedbackSlice.actions;

export default feedbackSlice.reducer;
