import {
  CreateNestingFolder,
  DeleteNestingFolder,
  DeleteNestingSession,
  FetchFullNestingProject,
  FolderHierarchy,
  FolderSearchType,
  GetExampleProject,
  GetNestingFolderHierarchy,
  HistoricNestingSessionSearch,
  MoveNestsIntoFolder,
  NestingProject,
  RenameNestingFolder,
  SavedNestingSessionSearch,
  SearchedNestingProject,
} from "../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteById,
  findFolderTreeById,
  flattenFolders,
  getParentsById,
  getTreeIds,
  guid,
} from "../../Util";
import {
  disposeNestingState,
  rehydrateNest,
  setIsExampleProject,
} from "../nesting/nestingSlice";

import { AppThunk } from "../../app/store";
import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./../common/commonTypes";
import { RootState } from "./../../app/store";
import { StaticFolder } from "../libraryPartList/libraryPartListSlice";
import cookie from "react-cookies";

export type SortDescriptor = {
  order: "desc" | "asc";
  property: string;
  title: string;
};

export type SavedNestsSearch = {
  dateFrom?: string;
  dateTo?: string;
  nestName?: string;
};

export type NestProject = { isSelected: boolean } & SearchedNestingProject;

export const savedNestsSortDescriptors: SortDescriptor[] = [
  {
    order: "desc",
    property: "name",
    title: "",
  },
  {
    order: "asc",
    property: "lastUpdated",
    title: "",
  },
];

type NestsState = {
  deleteNestError: string;
  deleteNestFolderLoading: Loading;
  deleteNestFolderModalVisibility: boolean;
  deleteNestLoading: Loading;
  exampleProjectModalVisibility: boolean;
  idempotencyToken: string;
  moveNestsToFolderLoading: Loading;
  moveNestsToFolderModalVisibility: boolean;
  nestDeleteModalOpen: boolean;
  nestFolders: FolderHierarchy[];
  nestFoldersFlattened: FolderHierarchy[];
  nestFoldersLastPulled: string;
  nestFoldersLoading: Loading;
  nestIdToDelete: string;
  nestSearchActive: boolean;
  nestSearchCollapse: boolean;
  recentNests: NestProject[];
  recentNestsError: string;
  recentNestsLastPulled: string;
  recentNestsLoading: Loading;
  recentNestsPage: number;
  recentNestsPerPage: number;
  rehydrateNestingSessionError: string;
  rehydrateNestingSessionLoading: Loading;
  rehydrateNestingSessionResponse: NestingProject;
  savedNests: NestProject[];
  savedNestsError: string;
  savedNestsLastPulled: string;
  savedNestsLoading: Loading;
  savedNestsPage: number;
  savedNestsPerPage: number;
  savedNestsSearch: SavedNestsSearch;
  savedNestsSortBy: SortDescriptor;
  savedNestsTotal: number;
  selectedNestFolder: FolderHierarchy | undefined;
  selectedNestFolderId: string;
  selectedNestFolderMoveId: string;
  selectedNestFolderParentTreeIds: string[];
  selectedNestFolderTreeIds: string[];
  selectedStaticNestFolder: StaticFolder;
};

const initialState: NestsState = {
  deleteNestError: "",
  deleteNestFolderLoading: "idle",
  deleteNestFolderModalVisibility: false,
  deleteNestLoading: "idle",
  exampleProjectModalVisibility: false,
  idempotencyToken: "",
  moveNestsToFolderLoading: "idle",
  moveNestsToFolderModalVisibility: false,
  nestDeleteModalOpen: false,
  nestFolders: [] as FolderHierarchy[],
  nestFoldersFlattened: [] as FolderHierarchy[],
  nestFoldersLastPulled: "",
  nestFoldersLoading: "idle",
  nestIdToDelete: "",
  nestSearchActive: false,
  nestSearchCollapse: false,
  recentNests: [] as NestProject[],
  recentNestsError: "",
  recentNestsLastPulled: "",
  recentNestsLoading: "idle",
  recentNestsPage: 1,
  recentNestsPerPage: 6,
  rehydrateNestingSessionError: "",
  rehydrateNestingSessionLoading: "idle",
  rehydrateNestingSessionResponse: new NestingProject(),
  savedNests: [] as NestProject[],
  savedNestsError: "",
  savedNestsLastPulled: "",
  savedNestsLoading: "idle",
  savedNestsPage: 1,
  savedNestsPerPage: cookie.load("NESTS-PER-PAGE") ?? 8,
  savedNestsSearch: {},
  savedNestsSortBy: cookie.load("NESTS-SORT") ?? savedNestsSortDescriptors[0],
  savedNestsTotal: 0,
  selectedNestFolder: new FolderHierarchy(),
  selectedNestFolderId: "",
  selectedNestFolderMoveId: "",
  selectedNestFolderParentTreeIds: [] as string[],
  selectedNestFolderTreeIds: [] as string[],
  selectedStaticNestFolder: "library",
};

export const fetchExampleProject = createAsyncThunk(
  "nestsSlice/fetchExampleProject",
  async (_, thunkAPI) => {
    //Remove any existing sheets such as default sheets - for dev purposes new users shouldn't have any
    thunkAPI.dispatch(disposeNestingState());

    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetExampleProject())
        .then((project) => {
          thunkAPI.dispatch(rehydrateNest(project));
          thunkAPI.dispatch(setIsExampleProject(true));
          return project;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const moveNestsToFolder = createAsyncThunk(
  "libraryPartListSlice/movePartsToFolder",

  async ({ folderId, nestsToMove }: Partial<MoveNestsIntoFolder>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(
          new MoveNestsIntoFolder({
            folderId,
            nestsToMove,
          })
        )
        .then((folders) => {
          return folders;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchNestsFolders = createAsyncThunk(
  "nestsSlice/fetchNestsFolders",

  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetNestingFolderHierarchy())
        .then((folders) => {
          thunkAPI.dispatch(setNestFoldersLastPulled(new Date().toString()));
          // Deep clone folders to avoid mutating state
          thunkAPI.dispatch(
            setNestFoldersFlattened(JSON.parse(JSON.stringify(folders)))
          );

          return folders;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchRecentNests = createAsyncThunk(
  "nestsSlice/fetchRecentNests",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(
          new HistoricNestingSessionSearch({
            skip: 0,
            take: 32,
          })
        )
        .then((data) => {
          thunkAPI.dispatch(setRecentNestsLastPulled(new Date().toString()));

          return data.results;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchSavedNests = createAsyncThunk(
  "nestsSlice/fetchSavedNests",
  async (
    {
      currentPage,
      folderId,
      searchType,
    }: Partial<SavedNestingSessionSearch> & { currentPage?: number },
    thunkAPI
  ) => {
    const tokenId = guid();
    thunkAPI.dispatch(setIdempotencyToken(tokenId));

    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      const {
        savedNestsPage: currentPageFromState,
        savedNestsPerPage,
        savedNestsSearch,
        savedNestsSortBy,
      } = (thunkAPI.getState() as RootState).nests as NestsState;

      const currPage = currentPage ?? currentPageFromState;

      const skip = (currPage - 1) * savedNestsPerPage;
      const take = savedNestsPerPage;
      let searchParams = {};
      if (
        typeof savedNestsSearch.nestName !== "undefined" &&
        savedNestsSearch.nestName !== ""
      ) {
        searchParams = {
          ...searchParams,
          nameLike: savedNestsSearch.nestName,
        };
      }
      if (
        typeof savedNestsSearch.dateFrom !== "undefined" &&
        savedNestsSearch.dateFrom !== "" &&
        typeof savedNestsSearch.dateTo !== "undefined" &&
        savedNestsSearch.dateTo !== ""
      ) {
        searchParams = {
          ...searchParams,

          fromDate: savedNestsSearch.dateFrom,
          toDate: savedNestsSearch.dateTo,
        };
      }
      const orderProperty =
        savedNestsSortBy.order === "asc"
          ? { orderBy: savedNestsSortBy.property }
          : { orderByDesc: savedNestsSortBy.property };

      return await client
        .get(
          new SavedNestingSessionSearch({
            skip,
            take,
            ...searchParams,
            ...orderProperty,
            searchType,
            folderId,
            idempotencyToken: tokenId,
          })
        )
        .then((data) => {
          thunkAPI.dispatch(setSavedNestsTotal(data.total));
          thunkAPI.dispatch(setSavedNestsLastPulled(new Date().toString()));

          thunkAPI.dispatch(setSavedNestsPage(currPage));

          const { idempotencyToken } = (thunkAPI.getState() as RootState)
            .nests as NestsState;

          if (data.idempotencyToken !== idempotencyToken) {
            return thunkAPI.abort("Aborted stale request");
          }

          return data.results;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const deleteNest = createAsyncThunk(
  "nestsSlice/deleteNest",
  async (nestingSessionId: string, thunkAPI) => {
    const { selectedNestFolderId, savedNestsPage } = (
      thunkAPI.getState() as RootState
    ).nests as NestsState;

    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .delete(
          new DeleteNestingSession({
            nestingSessionId,
          })
        )
        .then((response) => {
          if (response.success) {
            thunkAPI.dispatch(
              fetchSavedNests({
                currentPage: savedNestsPage,
                folderId: selectedNestFolderId,
                searchType: selectedNestFolderId
                  ? FolderSearchType.Subfolders
                  : FolderSearchType.Global,
              })
            );

            thunkAPI.dispatch(fetchRecentNests());
          }
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

// also set API-XSRF-TOKEN cookie
export const rehydrateNestingSession = createAsyncThunk(
  "nestsSlice/rehydrateNestingSession",
  async (projectId: string, thunkAPI) => {
    thunkAPI.dispatch(disposeNestingState());
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(new FetchFullNestingProject({ projectId }))
          .then((fetchedNestingSession) => {
            thunkAPI.dispatch(setIsExampleProject(false));
            thunkAPI.dispatch(rehydrateNest(fetchedNestingSession));
            return fetchedNestingSession;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const createNestFolder = createAsyncThunk(
  "nestsSlice/createNestFolder",
  async (
    { folderName, parentFolderId }: Partial<CreateNestingFolder>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(
          new CreateNestingFolder({
            folderName,
            parentFolderId,
          })
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const renameNestFolder = createAsyncThunk(
  "nestsSlice/renamePartsFolder",

  async ({ id, folderName }: Partial<RenameNestingFolder>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(
          new RenameNestingFolder({
            id,
            folderName,
          })
        )
        .then((folder) => {
          return folder;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const deleteNestFolder = createAsyncThunk(
  "nestsSlice/deleteNestFolder",

  async (
    { folderId, deleteNestsInFolder }: Partial<DeleteNestingFolder>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .delete(
          new DeleteNestingFolder({
            folderId,
            deleteNestsInFolder,
          })
        )
        .then((folder) => {
          return folder;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const nestsSlice = createSlice({
  initialState: initialState,
  name: "nestsSlice",
  reducers: {
    setExampleProjectModalVisibility(state, action: PayloadAction<boolean>) {
      state.exampleProjectModalVisibility = action.payload;
    },

    setDeleteNestFolderModalVisibility(state, action: PayloadAction<boolean>) {
      state.deleteNestFolderModalVisibility = action.payload;
    },

    setNestSearchCollapse(state, action: PayloadAction<boolean>) {
      state.nestSearchCollapse = action.payload;
    },

    setTemporaryNestFolder(state, action: PayloadAction<FolderHierarchy>) {
      // find parentId in state.partFolders and add new folder to it

      deleteById(state.nestFolders, "temp");

      function check(
        folders: FolderHierarchy[],
        parentId: string
      ): FolderHierarchy[] {
        // use "current()" to log the current value of the state array
        folders.forEach((folder) => {
          //remove nameless folders

          if (folder.name === "") {
            folders.splice(folders.indexOf(folder), 1);
          }

          if (folder.id === parentId) {
            state.selectedNestFolderParentTreeIds.push(parentId);

            folder.childFolders?.push(action.payload);
          } else if (Array.isArray(folder.childFolders)) {
            check(folder.childFolders, parentId);
          }
        });

        return folders;
      }

      if (!action.payload.parentId) {
        state.nestFolders = [action.payload, ...state.nestFolders];
      } else {
        state.nestFolders = [
          ...check(state.nestFolders, action.payload.parentId!),
        ];
      }
    },

    setSelectedNestFolderMoveId(state, action: PayloadAction<string>) {
      state.selectedNestFolderMoveId = action.payload;
    },

    setMoveNestsToFolderModalVisibility(state, action: PayloadAction<boolean>) {
      state.moveNestsToFolderModalVisibility = action.payload;
    },
    setIdempotencyToken(state, action: PayloadAction<string>) {
      state.idempotencyToken = action.payload;
    },

    setSelectedStaticNestFolder(state, action: PayloadAction<StaticFolder>) {
      state.selectedStaticNestFolder = action.payload;
    },

    setSelectedNestFolderId(state, action: PayloadAction<string>) {
      state.selectedNestFolderId = action.payload;

      const folder = findFolderTreeById(action.payload, state.nestFolders);

      const treeIds = getTreeIds(folder.childFolders, [action.payload]);

      state.selectedNestFolderTreeIds = treeIds;

      //Set Selected Folder Parent Tree Ids
      state.selectedNestFolderParentTreeIds = getParentsById(
        action.payload,
        state.nestFolders
      );

      //Set Selected Folder
      state.selectedNestFolder = state.nestFoldersFlattened.find(
        (partFolder) => partFolder.id === action.payload
      );
    },

    setNestFoldersLastPulled(state, action: PayloadAction<string>) {
      state.nestFoldersLastPulled = action.payload;
    },
    setSelectedNestFolderParentTreeIds(state, action: PayloadAction<string[]>) {
      state.selectedNestFolderParentTreeIds = action.payload;
    },

    setNestFoldersFlattened(state, action: PayloadAction<FolderHierarchy[]>) {
      state.nestFoldersFlattened = flattenFolders(action.payload);
    },
    setNestsSearchActive(state, action: PayloadAction<boolean>) {
      state.nestSearchActive = action.payload;
    },
    setNestDeleteModalOpen(state, action: PayloadAction<boolean>) {
      state.nestDeleteModalOpen = action.payload;
    },
    setNestIdToDelete(state, action: PayloadAction<string>) {
      state.nestIdToDelete = action.payload;
    },
    setRecentNestsLastPulled(state, action: PayloadAction<string>) {
      state.recentNestsLastPulled = action.payload;
    },
    setSavedNestsLastPulled(state, action: PayloadAction<string>) {
      state.savedNestsLastPulled = action.payload;
    },
    setSavedNestsSearch(
      state,
      action: PayloadAction<Partial<SavedNestsSearch>>
    ) {
      state.savedNestsSearch = {
        ...state.savedNestsSearch,
        ...action.payload,
      };
    },

    updateSavedNests(state, action: PayloadAction<Partial<NestProject>[]>) {
      const existingSessionIds = state.savedNests.map(
        (savedNest) => savedNest.id
      );
      // Map over each saved nest in the state and update it with the corresponding
      // saved nest in the payload
      const updatedSavedNest = state.savedNests.map((savedNest) => ({
        ...savedNest,
        ...action.payload.find((nest) => nest.id === savedNest.id),
      }));

      state.savedNests = [
        ...updatedSavedNest,
        ...action.payload
          // Filter out any new sessions that don't have an ID or whose ID is not included in extantSessionIds
          .filter(
            (newSession) =>
              typeof newSession.id === "undefined" ||
              !existingSessionIds.includes(newSession.id)
          )
          // Map each new session to a new object with the same properties as the new session
          // and cast it as a NestProject
          .map(
            (newSession) =>
              ({
                ...newSession,
              } as NestProject)
          ),
      ];
    },
    setSavedNestsPage(state, action: PayloadAction<number>) {
      state.savedNestsPage = action.payload;
    },
    setSavedNestsPerPage(state, action: PayloadAction<number>) {
      state.savedNestsPerPage = action.payload;

      cookie.save("NESTS-PER-PAGE", action.payload, {
        secure: false,
        sameSite: "strict",
        path: "/",
      });
    },
    setSavedNestsSortBy(state, action: PayloadAction<SortDescriptor>) {
      state.savedNestsSortBy = action.payload;

      cookie.save("NESTS-SORT", action.payload, {
        secure: false,
        sameSite: "strict",
        path: "/",
      });
    },
    setSavedNestsTotal(state, action: PayloadAction<number>) {
      state.savedNestsTotal = action.payload;
    },

    deselectAllSavedNests(state) {
      state.savedNests = state.savedNests.map((nest) => ({
        ...nest,
        isSelected: false,
      }));
    },
  },

  extraReducers: (builder) => {
    builder
      // =======================================================================
      // GET: Recent Nests
      .addCase(fetchRecentNests.pending, (state) => {
        state.recentNestsLoading = "pending";
        state.recentNestsError = "";
      })
      .addCase(fetchRecentNests.fulfilled, (state, action) => {
        state.recentNests = action.payload as NestProject[];
        state.recentNestsLoading = "succeeded";
        state.recentNestsError = "";
      })
      .addCase(fetchRecentNests.rejected, (state, rejectedAction) => {
        state.recentNestsLoading = "failed";
        state.recentNestsError = rejectedAction.payload as string;
      })
      // =======================================================================
      // GET: Saved Nests
      .addCase(fetchSavedNests.pending, (state) => {
        state.savedNestsLoading = "pending";
        state.savedNestsError = "";
      })
      .addCase(fetchSavedNests.fulfilled, (state, action) => {
        state.savedNests = action.payload as NestProject[];
        state.savedNestsLoading = "succeeded";
        state.savedNestsError = "";
      })
      .addCase(fetchSavedNests.rejected, (state, rejectedAction) => {
        state.savedNestsLoading = "failed";
        state.savedNestsError = rejectedAction.payload as string;
      })
      // =======================================================================
      // DELETE: Delete Nest
      .addCase(deleteNest.pending, (state) => {
        state.deleteNestLoading = "pending";
        state.deleteNestError = "";
      })
      .addCase(deleteNest.fulfilled, (state) => {
        state.deleteNestLoading = "succeeded";
        state.deleteNestError = "";
      })
      .addCase(deleteNest.rejected, (state, rejectedAction) => {
        state.deleteNestLoading = "failed";
        state.deleteNestError = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Rehydrate Nesting Session
      .addCase(rehydrateNestingSession.pending, (state) => {
        state.rehydrateNestingSessionResponse =
          initialState.rehydrateNestingSessionResponse;
        state.rehydrateNestingSessionLoading = "pending";
        state.rehydrateNestingSessionError = "";
      })
      .addCase(rehydrateNestingSession.fulfilled, (state, action) => {
        state.rehydrateNestingSessionResponse =
          action.payload as NestingProject;
        state.rehydrateNestingSessionLoading = "succeeded";
        state.rehydrateNestingSessionError = "";
      })
      .addCase(rehydrateNestingSession.rejected, (state, rejectedAction) => {
        state.rehydrateNestingSessionResponse =
          initialState.rehydrateNestingSessionResponse;
        state.rehydrateNestingSessionLoading = "failed";
        state.rehydrateNestingSessionError = rejectedAction.payload as string;
      })
      // =======================================================================
      // GET: Nest Folders
      .addCase(fetchNestsFolders.pending, (state) => {
        state.nestFoldersLoading = "pending";
      })
      .addCase(fetchNestsFolders.fulfilled, (state, action) => {
        state.nestFolders = action.payload as FolderHierarchy[];
        state.nestFoldersLoading = "succeeded";
      })
      .addCase(fetchNestsFolders.rejected, (state) => {
        state.nestFoldersLoading = "failed";
      })
      // =======================================================================
      // Put: Move Nests To Folder
      .addCase(moveNestsToFolder.pending, (state) => {
        state.moveNestsToFolderLoading = "pending";
      })
      .addCase(moveNestsToFolder.fulfilled, (state) => {
        state.moveNestsToFolderLoading = "succeeded";
      })
      .addCase(moveNestsToFolder.rejected, (state) => {
        state.moveNestsToFolderLoading = "failed";
      })
      // =======================================================================
      // DELETE: Delete Folder
      .addCase(deleteNestFolder.pending, (state) => {
        state.deleteNestFolderLoading = "pending";
      })
      .addCase(deleteNestFolder.fulfilled, (state) => {
        state.deleteNestFolderLoading = "succeeded";
      })
      .addCase(deleteNestFolder.rejected, (state) => {
        state.deleteNestFolderLoading = "failed";
      });
  },
});

export const updateSavedNestsSortBy =
  (sort: SortDescriptor): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setSavedNestsSortBy(sort));

    const { selectedNestFolderId, selectedStaticNestFolder } = (
      getState() as RootState
    ).nests as NestsState;

    window.requestAnimationFrame(() => {
      dispatch(
        fetchSavedNests({
          folderId: selectedNestFolderId,
          searchType:
            selectedNestFolderId || selectedStaticNestFolder === "non-foldered"
              ? FolderSearchType.Subfolders
              : FolderSearchType.Global,
        })
      );
    });
  };

export const {
  deselectAllSavedNests,
  setDeleteNestFolderModalVisibility,
  setExampleProjectModalVisibility,
  setIdempotencyToken,
  setMoveNestsToFolderModalVisibility,
  setNestDeleteModalOpen,
  setNestFoldersFlattened,
  setNestFoldersLastPulled,
  setNestIdToDelete,
  setNestSearchCollapse,
  setNestsSearchActive,
  setRecentNestsLastPulled,
  setSavedNestsLastPulled,
  setSavedNestsPage,
  setSavedNestsPerPage,
  setSavedNestsSearch,
  setSavedNestsSortBy,
  setSavedNestsTotal,
  setSelectedNestFolderId,
  setSelectedNestFolderMoveId,
  setSelectedNestFolderParentTreeIds,
  setSelectedStaticNestFolder,
  setTemporaryNestFolder,
  updateSavedNests,
} = nestsSlice.actions;

export default nestsSlice.reducer;
