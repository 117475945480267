import {
  ForwardRefRenderFunction,
  MutableRefObject,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Tooltip } from "bootstrap";

export type ExposedTooltipFuncs = {
  disposeToolTip: () => void;
};

export const EfficientTooltip: ForwardRefRenderFunction<
  ExposedTooltipFuncs,
  {
    children: ReactNode;
    className?: string;
    content: Array<string>;
    delayHide?: number;
    delayShow?: number;
    disabled?: boolean;
    fullWidth?: boolean;
    hide?: boolean;
    placement?: string;
    show?: boolean;
    showBorder?: boolean;
    trigger?: string;
    upgradable?: boolean;
  }
> = (
  {
    children,
    className,
    content,
    delayHide = 0,
    delayShow = 0,
    disabled,
    fullWidth,
    hide,
    placement = "top",
    show,
    showBorder,
    trigger = "hover",
    upgradable,
  },
  ref
) => {
  const toolTipRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [showHoverBorder, setShowHoverBorder] = useState(false);

  const showToolTip = useCallback(() => {
    const bsToolTip = Tooltip.getInstance(toolTipRef.current);

    bsToolTip?.show();
  }, []);

  const hideToolTip = useCallback(() => {
    const bsToolTip = Tooltip.getInstance(toolTipRef.current);

    bsToolTip?.hide();
  }, []);

  // expose disposeToolTip to the reference passed in
  useImperativeHandle(
    ref,
    () => {
      return {
        disposeToolTip() {
          const bsToolTip = Tooltip.getInstance(toolTipRef.current);

          bsToolTip?.dispose();
        },
      };
    },
    []
  );

  useEffect(() => {
    if (show) {
      if (!!content.length) {
        showToolTip();
      }
    } else if (hide) {
      hideToolTip();
    }
  }, [show, hide, content, showToolTip, hideToolTip]);

  const borderShow = useCallback(() => {
    showBorder && setShowHoverBorder(true);
  }, [showBorder]);

  const borderHide = useCallback(() => {
    showBorder && setShowHoverBorder(false);
  }, [showBorder]);

  useEffect(() => {
    const toolTip = toolTipRef.current;

    if (!!content.length) {
      toolTip.addEventListener("hidden.bs.tooltip", borderHide);
      toolTip.addEventListener("show.bs.tooltip", borderShow);

      const template = content.map((item) => {
        return `<div><span class="theme-white-space-pre-line">${item}</span></div>`;
      });

      Tooltip.getOrCreateInstance(toolTip, {
        customClass: "theme-tooltip-efficient",
        title: template.join("") ?? "",
        placement: placement,
        container: "body",
        fallbackPlacements: ["right", "top", "bottom", "left"],
        //Change trigger to click for testing
        trigger: trigger,
        // comment out line below to view html elements in render
        html: true,
        // increase delay to view tooltip longer
        delay: { show: delayShow, hide: delayHide },
      });
    }
    return () => {
      Tooltip.getInstance(toolTip)?.dispose();

      toolTip && toolTip.removeEventListener("hidden.bs.tooltip", borderHide);
      toolTip && toolTip.removeEventListener("show.bs.tooltip", borderShow);
    };
  }, [
    borderHide,
    showHoverBorder,
    borderShow,
    content,
    delayHide,
    delayShow,

    placement,
    toolTipRef,
    trigger,
    upgradable,
  ]);

  return !!content.length ? (
    <div
      ref={toolTipRef}
      className={`position-relative d-inline-block theme-tooltip-wrapper ${
        disabled ? "disabled" : ""
      } ${fullWidth ? "w-100" : ""} ${className ?? ""} ${
        showHoverBorder ? "theme-shadow-outline" : ""
      } `}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default forwardRef(EfficientTooltip);
