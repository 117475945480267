import { AppDispatch, RootState } from "./../../app/store";
import {
  Assignment,
  CancelSessionRequest,
  CheckIfPartNamesExist,
  CompleteSessionRequest,
  CreateFileSettings,
  CreateImportSession,
  DeleteFileIdentity,
  FileDeleteResult,
  FileUpdatedResult,
  FileUploadResult,
  FinaliseSessionResult,
  GetAssignmentTypes,
  ImportSessionResult,
  MeasurementUnits,
  ModelRetrievedResult,
  ModelUpdateResult,
  Ratio,
  ResultRetrievedResult,
  RetrieveModelIdentity,
  RetrieveResultIdentity,
  UpdateFileSettings,
  UpdateModelSettings,
  UserSettings,
} from "../../serviceClient/api.dtos";
import {
  CameraBehaviour,
  ImporterFile,
  ImporterFileLayer,
  ImporterFileModel,
  ImporterFilePart,
  ImporterFileResult,
  ImporterFileSettings,
  ImporterSettings,
  ImporterType,
  Session,
} from "./importerTypes.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { finaliseSession, updateResults } from "./importerSliceThunks";

import { HubConnectionState } from "@microsoft/signalr";
import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../common/commonTypes";
import { NavigateFunction } from "react-router";

export type ImporterState = {
  busyFinalisingSession: boolean;
  checkIfPartNamesExistLoading: Loading;
  completeSessionRequest: FinaliseSessionResult;
  completeSessionRequestErrorMessage: String;
  completeSessionRequestLoading: Loading;
  createFileSettingsErrorMessage: string;
  createFileSettingsLoading: Loading;
  createFileSettingsResponse: FileUploadResult;
  createImportSessionErrorMessage: string;
  createImportSessionLoading: Loading;
  createImportSessionResponse: ImportSessionResult;
  deleteFileIdentityErrorMessage: string;
  deleteFileIdentityLoading: Loading;
  deleteFileIdentityResponse: FileDeleteResult;
  duplicatePartNamesModalOpen: boolean;
  errorMessage: string;
  existingPartNames: ImporterFilePart[];
  existingPartNamesComplete: ImporterFilePart[];
  fileSettings: ImporterFileSettings[];
  files: ImporterFile[];
  importSessionDirty: boolean;
  importTimerModalVisibility: boolean;
  importerPartModalOpen: boolean;
  importerSignalRConnectionState: signalR.HubConnectionState;
  importerType: ImporterType;
  layerAssignments: Assignment[];
  layerAssignmentsErrorMessage: String;
  layerAssignmentsLoading: Loading;
  layers: ImporterFileLayer[];
  modelRequest: ModelRetrievedResult;
  modelRequestErrorMessage: string;
  modelRequestLoading: Loading;
  models: ImporterFileModel[];
  parts: ImporterFilePart[];
  results: ImporterFileResult[];
  retrieveResultIdentity: RetrieveResultIdentity;
  retrieveResultIdentityErrorMessage: string;
  retrieveResultIdentityLoading: Loading;
  session: Session;
  sessionRequestActive: boolean;
  settings: ImporterSettings;
  updateFileSettingsErrorMessage: string;
  updateFileSettingsLoading: Loading;
  updateFileSettingsResponse: FileUpdatedResult;
  updateModelSettingsErrorMessage: string;
  updateModelSettingsLoading: Loading;
  updateModelSettingsResponse: ModelUpdateResult;
  windowImporterLobbyFiles: File[];
};

const initialState: ImporterState = {
  busyFinalisingSession: false,
  checkIfPartNamesExistLoading: "idle",
  completeSessionRequest: new FinaliseSessionResult(),
  completeSessionRequestErrorMessage: "",
  completeSessionRequestLoading: "idle",
  createFileSettingsErrorMessage: "",
  createFileSettingsLoading: "idle",
  createFileSettingsResponse: new FileUploadResult(),
  createImportSessionErrorMessage: "",
  createImportSessionLoading: "idle",
  createImportSessionResponse: new ImportSessionResult(),
  deleteFileIdentityErrorMessage: "",
  deleteFileIdentityLoading: "idle",
  deleteFileIdentityResponse: new FileDeleteResult(),
  duplicatePartNamesModalOpen: false,
  errorMessage: "",
  existingPartNames: [],
  existingPartNamesComplete: [],
  fileSettings: [],
  files: [],
  importSessionDirty: false,
  importTimerModalVisibility: false,
  importerPartModalOpen: false,
  importerSignalRConnectionState: HubConnectionState.Disconnected,
  importerType: "parts",
  layerAssignments: [],
  layerAssignmentsErrorMessage: "",
  layerAssignmentsLoading: "idle",
  layers: [],
  modelRequest: new ModelRetrievedResult(),
  modelRequestErrorMessage: "",
  modelRequestLoading: "idle",
  models: [],
  parts: [],
  results: [],
  retrieveResultIdentity: new RetrieveResultIdentity(),
  retrieveResultIdentityErrorMessage: "",
  retrieveResultIdentityLoading: "idle",
  session: { maxFileSizeInMb: 1, sessionId: "", settings: new UserSettings() },
  sessionRequestActive: false,
  settings: {
    drawingScale: { from: 1, to: 1 } as Ratio,
    measurementUnits: MeasurementUnits.Millimetres,
    panZoomMode: CameraBehaviour.MoveCamera,
  },
  updateFileSettingsErrorMessage: "",
  updateFileSettingsLoading: "idle",
  updateFileSettingsResponse: new FileUpdatedResult(),
  updateModelSettingsErrorMessage: "",
  updateModelSettingsLoading: "idle",
  updateModelSettingsResponse: new ModelUpdateResult(),
  windowImporterLobbyFiles: [],
};

export const cancelSessionRequest = createAsyncThunk(
  "nesting/cancelSessionRequest",
  async (_, thunkAPI) => {
    const {
      importer: {
        session: { sessionId },
      },
    } = thunkAPI.getState() as RootState;

    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .delete(
          new CancelSessionRequest({
            sessionId,
          })
        )
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const checkIfPartNamesExist = createAsyncThunk(
  "importerSlice/checkIfPartNamesExist",

  async (
    { names, navigate }: { names: string[]; navigate: NavigateFunction },
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      const {
        libraryPartList: { selectedPartFolderId },
      } = thunkAPI.getState() as RootState;

      return await client
        .post(
          new CheckIfPartNamesExist({
            names,
            folderId: selectedPartFolderId,
          })
        )
        .then((data): ImporterFilePart[] => {
          //are there any duplicates in the importer state parts

          const {
            importer: { parts },
          } = thunkAPI.getState() as RootState;

          const existingPartNamesComplete = parts.filter((item) =>
            data.names.includes(item.name)
          );
          //  Define a function that finds any duplicate names inside parts array and extract the objects to a new array
          const findDuplicates = (arr: ImporterFilePart[]) =>
            arr.filter(
              (item, index) =>
                arr.findIndex((item2) => item.name === item2.name) !== index
            );

          const localTest = existingPartNamesComplete.concat(
            findDuplicates(parts)
          );
          if (localTest.length === 0) {
            thunkAPI.dispatch(
              finaliseSession(false, navigate, true) as AppDispatch
            );
          }

          return localTest;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const deleteFileIdentity = createAsyncThunk(
  "importerSlice/deleteFileIdentity",
  async ({ fileId, sessionId }: DeleteFileIdentity, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .delete(
          new DeleteFileIdentity({
            fileId,
            sessionId,
          })
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const updateFileSettings = createAsyncThunk(
  "importerSlice/updateFileSettings",
  async (
    {
      fileId,
      fileSettings,

      sessionId,
      uniqueRequestId,
    }: UpdateFileSettings,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(
          new UpdateFileSettings({
            fileId,
            fileSettings,

            sessionId,
            uniqueRequestId,
          })
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const updateModelSettings = createAsyncThunk(
  "importerSlice/updateModelSettings",
  async (
    {
      fileId,
      sessionId,
      layerChanges,
      groupPartAsDrawn,
      uniqueRequestId,
    }: UpdateModelSettings,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(
          new UpdateModelSettings({
            uniqueRequestId,
            fileId,
            sessionId,
            layerChanges,
            groupPartAsDrawn,
          })
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const createFileSettings = createAsyncThunk(
  "importerSlice/createFileSettings",
  async (newCreateFileSetting: CreateFileSettings, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(newCreateFileSetting)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const createImportSession = createAsyncThunk(
  "importerSlice/createImportSession",
  async (newSessionRequest: CreateImportSession, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(newSessionRequest)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const retrieveModelIdentity = createAsyncThunk(
  "importerSlice/retrieveModelIdentity",
  async (newModelRequest: RetrieveModelIdentity, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      const {
        importer: {
          session: { sessionId },
        },
      } = thunkAPI.getState() as RootState;

      return await client
        .get(
          new RetrieveModelIdentity({
            fileId: newModelRequest.fileId,
            sessionId,
          })
        )
        .then((model) => {
          const { layers } = model;

          // Remove potential old layers

          const {
            importer: { models: modelsInState, layers: layersInState },
          } = thunkAPI.getState() as RootState;

          const oldModel = modelsInState.find(
            (oldFileModel) => oldFileModel.fileId === newModelRequest.fileId
          );

          if (typeof oldModel !== "undefined") {
            thunkAPI.dispatch(
              removeLayers(
                layersInState.filter(
                  (oldLayer) => oldLayer.modelId === oldModel.uiIdentifier
                )
              )
            );
            thunkAPI.dispatch(removeModels([oldModel]));
          }

          thunkAPI.dispatch(
            setLayers(
              layers.map((layer) => ({
                fileId: model.fileId,
                colourHex: layer.colourHex,
                instruction: layer.instruction,
                layerName: layer.layerName,
                lineType: layer.lineType,
                modelId: model.uiIdentifier,
                uiIdentifier: layer.uiIdentifier,
                visible: layer.visible,
              }))
            )
          );

          thunkAPI.dispatch(
            setModels([
              {
                base64SVG: model.base64SVG,
                fileId: model.fileId,
                sessionId: model.sessionId,
                uiIdentifier: model.uiIdentifier,
              },
            ])
          );

          return model;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const retrieveResultIdentity = createAsyncThunk(
  "importerSlice/retrieveResultIdentity",
  async (newResultRequest: RetrieveResultIdentity, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      const {
        importer: {
          session: { sessionId },
        },
      } = thunkAPI.getState() as RootState;

      const {
        importer: { importerType },
      } = thunkAPI.getState() as RootState;

      const {
        settings: {
          userSettings: { defaultSheetQuantity },
        },
      } = thunkAPI.getState() as RootState;

      return await client
        .get(
          new RetrieveResultIdentity({
            fileId: newResultRequest.fileId,
            uniqueRequestId: newResultRequest.uniqueRequestId,
            sessionId,
          })
        )
        .then((retrievedResultIdentity) => {
          //Reset Group as Drawn if parts are more than one

          const {
            importer: {
              results: resultsInState,
              parts: partsInState,
              files: filesInState,
            },
          } = thunkAPI.getState() as RootState;

          const oldResult = resultsInState.find(
            (oldFileResult) => oldFileResult.fileId === newResultRequest.fileId
          );

          let setFile: Partial<ImporterFile> = {
            awaitingUpdate: false,
            fileId: newResultRequest.fileId,
            uniqueRequestId: newResultRequest.uniqueRequestId,
            fileStatus: retrievedResultIdentity?.result?.status,
          };

          if (typeof oldResult !== "undefined") {
            // If the groupPartsAsDrawn is true and there are more than one part coming back from the API
            // set the bool back to false - used for toggle button.
            // This can happen if groupPartsAsDrawn is true but the user has changed a layer assignment.
            // The part coming back will be ungrouped therefore we need to update to a un-toggled the button
            if (retrievedResultIdentity.result.parts.length > 1) {
              const fileInState = filesInState.find(
                (res) => res.fileId === oldResult.fileId
              );

              if (fileInState?.groupPartAsDrawn === true) {
                setFile.groupPartAsDrawn = false;
              }
            }

            thunkAPI.dispatch(
              removeParts(
                partsInState.filter(
                  (oldPart) => oldPart.resultId === oldResult.uiIdentifier
                )
              )
            );
            thunkAPI.dispatch(removeResults([oldResult]));
          }

          thunkAPI.dispatch(setFiles([setFile]));

          const parts = retrievedResultIdentity.result.parts.map((part) => ({
            ...part,
            //If importing sheets
            quantity:
              importerType === "sheets" ? defaultSheetQuantity : part.quantity,
            isActive: false,
            resultId: retrievedResultIdentity.result.uiIdentifier,
          }));

          thunkAPI.dispatch(
            setParts(
              parts.map((part) => ({
                ...part,
                resultId: retrievedResultIdentity.result.uiIdentifier,
              }))
            )
          );

          thunkAPI.dispatch(updateResults([retrievedResultIdentity.result]));

          return retrievedResultIdentity;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const completeSessionRequest = createAsyncThunk(
  "importerSlice/completeSessionRequest",
  async (newCompleteSessionRequest: CompleteSessionRequest, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .put(new CompleteSessionRequest(newCompleteSessionRequest))
        .then((finaliseResult) => {
          return finaliseResult;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchLayerAssignments = createAsyncThunk(
  "importerSlice/getAssignmentTypes",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetAssignmentTypes())
        .then((newAssignments) => {
          return newAssignments;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const importerSlice = createSlice({
  initialState,
  name: "importerSlice",
  reducers: {
    disposeImporterState: () => initialState,

    disposeImporterStateImporterModalState: (state) => {
      return {
        ...initialState,
        importerType: state.importerType,
        importerPartModalOpen: state.importerPartModalOpen,
      };
    },

    setImporterSignalRConnectionState(
      state,
      action: PayloadAction<signalR.HubConnectionState>
    ) {
      state.importerSignalRConnectionState = action.payload;
    },

    setImportTimerModalVisibility(state, action: PayloadAction<boolean>) {
      state.importTimerModalVisibility = action.payload;
    },

    setImporterType(state, action: PayloadAction<ImporterType>) {
      state.importerType = action.payload;
    },
    setImporterPartModalOpen(state, action: PayloadAction<boolean>) {
      state.importerPartModalOpen = action.payload;
    },
    setBusyFinalisingSession(state, action: PayloadAction<boolean>) {
      state.busyFinalisingSession = action.payload;
    },
    setFileSettings(
      state,
      action: PayloadAction<Partial<ImporterFileSettings>[]>
    ) {
      const extantSettingsIds = state.fileSettings.map(
        (extantSetting) => extantSetting.fileId
      );

      const updatedSettings = state.fileSettings.map((extantSetting) => ({
        ...extantSetting,
        ...action.payload.find(
          (newSetting) => newSetting.fileId === extantSetting.fileId
        ),
      }));

      state.fileSettings = [
        ...updatedSettings,
        ...action.payload
          .filter(
            (newSetting) =>
              typeof newSetting.fileId === "undefined" ||
              !extantSettingsIds.includes(newSetting.fileId)
          )
          .map(
            (newSetting) =>
              ({
                ...newSetting,
              } as ImporterFileSettings)
          ),
      ];
    },
    setImportSessionDirty(state, action: PayloadAction<boolean>) {
      state.importSessionDirty = action.payload;
    },
    setFiles(state, action: PayloadAction<Partial<ImporterFile>[]>) {
      //if no existing files set this one selected
      if (state.files.length <= 0 && action.payload.length > 0) {
        action.payload[0] = {
          ...action.payload[0],
          display: true,
          selected: true,
        };
      }
      state.importSessionDirty = true;
      const extantFileIds = state.files.map((extantFile) => extantFile.fileId);

      const extantFileReferences = state.files.map(
        (extantFile) => extantFile.reference
      );

      // update existing files e.g. response id from server or awaitingUpdate or fileStatus
      const updatedFiles = state.files.map((extantFile) => ({
        ...extantFile,
        ...action.payload.find(
          (newFile) =>
            newFile.reference === extantFile.reference ||
            (extantFile.fileId && newFile.fileId === extantFile.fileId)
        ),
      }));

      state.files = [
        ...updatedFiles,
        ...action.payload
          .filter(
            (newFile) =>
              (typeof newFile.reference === "undefined" ||
                !extantFileReferences.includes(newFile.reference)) &&
              (typeof newFile.fileId === "undefined" ||
                !extantFileIds.includes(newFile.fileId))
          )
          .map(
            (newFile) =>
              ({
                ...newFile,
              } as ImporterFile)
          ),
      ];
    },
    setLayers(state, action: PayloadAction<Partial<ImporterFileLayer>[]>) {
      const extantLayerIds = state.layers.map(
        (extantModel) => extantModel.uiIdentifier
      );

      const updatedLayers = state.layers.map((extantLayer) => ({
        ...extantLayer,
        ...action.payload.find(
          (newLayer) => newLayer.uiIdentifier === extantLayer.uiIdentifier
        ),
      }));

      state.layers = [
        ...updatedLayers,
        ...action.payload
          .filter(
            (newLayer) =>
              typeof newLayer.uiIdentifier === "undefined" ||
              !extantLayerIds.includes(newLayer.uiIdentifier)
          )
          .map(
            (newLayer) =>
              ({
                ...newLayer,
              } as ImporterFileLayer)
          ),
      ];
    },
    setModels(state, action: PayloadAction<Partial<ImporterFileModel>[]>) {
      const extantModelIds = state.models.map(
        (extantModel) => extantModel.uiIdentifier
      );

      const updatedModels = state.models.map((extantModel) => ({
        ...extantModel,
        ...action.payload.find(
          (newModel) => newModel.uiIdentifier === extantModel.uiIdentifier
        ),
      }));

      state.models = [
        ...updatedModels,
        ...action.payload
          .filter(
            (newModel) =>
              typeof newModel.uiIdentifier === "undefined" ||
              !extantModelIds.includes(newModel.uiIdentifier)
          )
          .map(
            (newModel) =>
              ({
                ...newModel,
              } as ImporterFileModel)
          ),
      ];
    },
    setParts(state, action: PayloadAction<Partial<ImporterFilePart>[]>) {
      const extantPartIds = state.parts.map((extantPart) => extantPart.partId);

      const updatedParts = state.parts.map((extantPart) => ({
        ...extantPart,
        ...action.payload.find(
          (newPart) => newPart.partId === extantPart.partId
        ),
      }));

      state.parts = [
        ...updatedParts,
        ...action.payload
          .filter(
            (newPart) =>
              typeof newPart.partId === "undefined" ||
              !extantPartIds.includes(newPart.partId)
          )
          .map(
            (newPart) =>
              ({
                ...newPart,
              } as ImporterFilePart)
          ),
      ];
    },
    setResults(state, action: PayloadAction<ImporterFileResult[]>) {
      state.results = action.payload;
    },
    setSession(state, action: PayloadAction<Session>) {
      state.session = action.payload;
    },
    setSessionRequestActive(state, action: PayloadAction<boolean>) {
      state.sessionRequestActive = action.payload;
    },
    deleteImportParts(state) {
      state.parts = [];
    },

    removeParts(state, action: PayloadAction<Partial<ImporterFilePart>[]>) {
      const partIdsToRemove = action.payload.map(
        (partToRemove) => partToRemove.partId
      );

      state.parts = state.parts.filter(
        (statePart) => !partIdsToRemove.includes(statePart.partId)
      );
    },
    removeLayers(state, action: PayloadAction<Partial<ImporterFileLayer>[]>) {
      const layerIdsToRemove = action.payload.map(
        (layerToRemove) => layerToRemove.uiIdentifier
      );

      state.layers = state.layers.filter(
        (stateLayer) => !layerIdsToRemove.includes(stateLayer.uiIdentifier)
      );
    },
    removeFiles(state, action: PayloadAction<Partial<ImporterFile>[]>) {
      const fileIdsToRemove = action.payload.map((file) => file.fileId);
      const fileReferencesToRemove = action.payload.map(
        (file) => file.reference
      );

      state.files = state.files.filter(
        (file) =>
          !fileIdsToRemove.includes(file.fileId) &&
          !fileReferencesToRemove.includes(file.reference)
      );
    },
    removeModels(state, action: PayloadAction<Partial<ImporterFileModel>[]>) {
      const modelIdsToRemove = action.payload.map(
        (model) => model.uiIdentifier
      );

      state.models = state.models.filter(
        (model) => !modelIdsToRemove.includes(model.uiIdentifier)
      );
    },
    removeResults(state, action: PayloadAction<Partial<ImporterFileResult>[]>) {
      const resultIdsToRemove = action.payload.map(
        (result) => result.uiIdentifier
      );

      state.results = state.results.filter(
        (result) => !resultIdsToRemove.includes(result.uiIdentifier)
      );
    },
    setDuplicatePartNamesModalOpen(state, action: PayloadAction<boolean>) {
      state.duplicatePartNamesModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // =======================================================================
      // GET: Check If Part Names Exist
      .addCase(checkIfPartNamesExist.pending, (state) => {
        state.checkIfPartNamesExistLoading = "pending";
        state.errorMessage = "";
      })
      .addCase(checkIfPartNamesExist.fulfilled, (state, action) => {
        state.existingPartNames = action.payload as ImporterFilePart[];
        state.errorMessage = "";
        state.checkIfPartNamesExistLoading = "succeeded";
      })
      .addCase(checkIfPartNamesExist.rejected, (state, rejectedAction) => {
        state.existingPartNames = [] as ImporterFilePart[];
        state.checkIfPartNamesExistLoading = "failed";
        state.errorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // DELETE: Delete File Identity
      .addCase(deleteFileIdentity.pending, (state) => {
        state.deleteFileIdentityResponse =
          initialState.deleteFileIdentityResponse;
        state.deleteFileIdentityLoading = "pending";
        state.deleteFileIdentityErrorMessage = "";
      })
      .addCase(deleteFileIdentity.fulfilled, (state, action) => {
        state.deleteFileIdentityResponse = action.payload as FileDeleteResult;
        state.deleteFileIdentityLoading = "succeeded";
        state.deleteFileIdentityErrorMessage = "";
      })
      .addCase(deleteFileIdentity.rejected, (state, rejectedAction) => {
        state.deleteFileIdentityResponse =
          initialState.deleteFileIdentityResponse;
        state.deleteFileIdentityLoading = "failed";
        state.deleteFileIdentityErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Update File Settings
      .addCase(updateFileSettings.pending, (state) => {
        state.updateFileSettingsResponse =
          initialState.updateFileSettingsResponse;
        state.updateFileSettingsLoading = "pending";
        state.updateFileSettingsErrorMessage = "";
      })
      .addCase(updateFileSettings.fulfilled, (state, action) => {
        state.updateFileSettingsResponse = action.payload as FileUpdatedResult;
        state.updateFileSettingsLoading = "succeeded";
        state.updateFileSettingsErrorMessage = "";
      })
      .addCase(updateFileSettings.rejected, (state, rejectedAction) => {
        state.updateFileSettingsResponse =
          initialState.updateFileSettingsResponse;
        state.updateFileSettingsLoading = "failed";
        state.updateFileSettingsErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Update Model Settings
      .addCase(updateModelSettings.pending, (state) => {
        state.updateModelSettingsResponse =
          initialState.updateModelSettingsResponse;
        state.updateModelSettingsLoading = "pending";
        state.updateModelSettingsErrorMessage = "";
      })
      .addCase(updateModelSettings.fulfilled, (state, action) => {
        state.updateModelSettingsResponse = action.payload as ModelUpdateResult;
        state.updateModelSettingsLoading = "succeeded";
        state.updateModelSettingsErrorMessage = "";
      })
      .addCase(updateModelSettings.rejected, (state, rejectedAction) => {
        state.updateModelSettingsResponse =
          initialState.updateModelSettingsResponse;
        state.updateModelSettingsLoading = "failed";
        state.updateModelSettingsErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // POST: Create File Settings
      .addCase(createFileSettings.pending, (state) => {
        // state.createFileSettingsResponse =
        //   initialState.createFileSettingsResponse;
        state.createFileSettingsLoading = "pending";
        state.createFileSettingsErrorMessage = "";
      })
      .addCase(createFileSettings.fulfilled, (state, action) => {
        state.createFileSettingsResponse = action.payload as FileUploadResult;
        state.createFileSettingsLoading = "succeeded";
        state.createFileSettingsErrorMessage = "";
      })
      .addCase(createFileSettings.rejected, (state, rejectedAction) => {
        // state.createFileSettingsResponse =
        //   initialState.createFileSettingsResponse;
        state.createFileSettingsLoading = "failed";
        state.createFileSettingsErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // POST: Create Import Session
      .addCase(createImportSession.pending, (state) => {
        state.createImportSessionResponse =
          initialState.createImportSessionResponse;
        state.createImportSessionLoading = "pending";
        state.createImportSessionErrorMessage = "";
      })
      .addCase(createImportSession.fulfilled, (state, action) => {
        state.createImportSessionResponse =
          action.payload as ImportSessionResult;
        state.createImportSessionLoading = "succeeded";
        state.createImportSessionErrorMessage = "";
      })
      .addCase(createImportSession.rejected, (state, rejectedAction) => {
        state.createImportSessionResponse =
          initialState.createImportSessionResponse;
        state.createImportSessionLoading = "failed";
        state.createImportSessionErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Retrieve Model Identity
      .addCase(retrieveModelIdentity.pending, (state) => {
        state.modelRequest = initialState.modelRequest;
        state.modelRequestLoading = "pending";
        state.modelRequestErrorMessage = "";
      })
      .addCase(retrieveModelIdentity.fulfilled, (state, action) => {
        state.modelRequest = action.payload as ModelRetrievedResult;
        state.modelRequestLoading = "succeeded";
        state.modelRequestErrorMessage = "";
      })
      .addCase(retrieveModelIdentity.rejected, (state, rejectedAction) => {
        state.modelRequest = initialState.modelRequest;
        state.modelRequestLoading = "failed";
        state.modelRequestErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Retrieve Result Identity
      .addCase(retrieveResultIdentity.pending, (state) => {
        state.retrieveResultIdentity = initialState.retrieveResultIdentity;
        state.retrieveResultIdentityLoading = "pending";
        state.retrieveResultIdentityErrorMessage = "";
      })
      .addCase(retrieveResultIdentity.fulfilled, (state, action) => {
        state.retrieveResultIdentity =
          action.payload as RetrieveResultIdentity & ResultRetrievedResult;
        state.retrieveResultIdentityLoading = "succeeded";
        state.retrieveResultIdentityErrorMessage = "";
      })
      .addCase(retrieveResultIdentity.rejected, (state, rejectedAction) => {
        state.retrieveResultIdentity = initialState.retrieveResultIdentity;
        state.retrieveResultIdentityLoading = "failed";
        state.retrieveResultIdentityErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // PUT: Complete Session Request
      .addCase(completeSessionRequest.pending, (state) => {
        state.completeSessionRequest = initialState.completeSessionRequest;
        state.completeSessionRequestLoading = "pending";
        state.completeSessionRequestErrorMessage = "";
      })
      .addCase(completeSessionRequest.fulfilled, (state, action) => {
        state.completeSessionRequest = action.payload as FinaliseSessionResult;
        state.completeSessionRequestLoading = "succeeded";
        state.completeSessionRequestErrorMessage = "";
      })
      .addCase(completeSessionRequest.rejected, (state, rejectedAction) => {
        state.completeSessionRequest = initialState.completeSessionRequest;
        state.completeSessionRequestLoading = "failed";
        state.completeSessionRequestErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      // GET: Complete Session Request
      .addCase(fetchLayerAssignments.pending, (state) => {
        state.layerAssignments = initialState.layerAssignments;
        state.layerAssignmentsLoading = "pending";
        state.layerAssignmentsErrorMessage = "";
      })
      .addCase(fetchLayerAssignments.fulfilled, (state, action) => {
        state.layerAssignments = action.payload as Assignment[];
        state.layerAssignmentsLoading = "succeeded";
        state.layerAssignmentsErrorMessage = "";
      })
      .addCase(fetchLayerAssignments.rejected, (state, rejectedAction) => {
        state.layerAssignments = initialState.layerAssignments;
        state.layerAssignmentsLoading = "failed";
        state.layerAssignmentsErrorMessage = rejectedAction.payload as string;
      });
  },
});

export const {
  disposeImporterState,
  disposeImporterStateImporterModalState,
  removeFiles,
  removeLayers,
  removeModels,
  removeParts,
  removeResults,
  setBusyFinalisingSession,
  setDuplicatePartNamesModalOpen,
  setFileSettings,
  setFiles,
  setImportSessionDirty,
  setImportTimerModalVisibility,
  setImporterPartModalOpen,
  setImporterSignalRConnectionState,
  setImporterType,
  setLayers,
  setModels,
  setParts,
  setResults,
  setSession,
  setSessionRequestActive,
} = importerSlice.actions;

export default importerSlice.reducer;
