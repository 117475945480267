import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  fetchTeamStatus,
  removeUserFromTeam,
  rescindInvite,
  setRemoveMemberModalVisibility,
  setSelectedMember,
} from "./teamsSlice";
import {
  getRemoveMemberLoading,
  getRemoveMemberModalVisibility,
  getRescindInviteLoading,
  getSelectedMember,
} from "./teams";
import {
  useAppDispatch,
  useAppSelector,
} from "../../app/hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MemberStatus } from "../../serviceClient/api.dtos";
import { Modal } from "bootstrap";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
import { guid } from "../../Util";
import { initialApiCalls } from "../../app/store";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const RemoveRevokeMemberDynamicModal: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const removeMemberModalVisibility = useAppSelector(
    getRemoveMemberModalVisibility
  );

  const selectedMember = useAppSelector(getSelectedMember);

  const removeMemberLoading = useAppSelector(getRemoveMemberLoading);
  const rescindInviteLoading = useAppSelector(getRescindInviteLoading);

  const [localSelectedMember, setLocalSelectedMember] = useState<
    MemberStatus | undefined
  >(undefined);

  const modalRef = useRef() as MutableRefObject<HTMLDivElement>;
  const hiddenModal = useCallback(() => {
    dispatch(setRemoveMemberModalVisibility(false));
    dispatch(setSelectedMember(null));
    if (window.location.hash) {
      navigate(window.location.pathname);
    }
  }, [dispatch, navigate]);

  const hideModal = useCallback(() => {
    const modalElement = modalRef.current;
    const bsModal = Modal.getInstance(modalElement);
    bsModal?.hide();
  }, []);

  const showModal = useCallback(() => {
    // Fetch team status to ensure we have the latest data
    // a user may have accepted, removed or left the team

    dispatch(fetchTeamStatus())
      .unwrap()
      .then((data) => {
        // Find the selected member in the updated team status
        const updatedSelectedMember = data.members.find(
          (member) => member.id === selectedMember?.id
        );
        if (!updatedSelectedMember) {
          // If the selected member is not found, close the modal
          hideModal();
          return;
        } else {
          // update the selected member locally to this component - globally causes infinite loop
          setLocalSelectedMember(updatedSelectedMember);
        }
      });
    // fetch

    navigate(t("paths.teamsRemoveHash") ?? "");
    const modalElement = modalRef.current;
    const bsModal = Modal.getOrCreateInstance(modalElement, {});

    bsModal.show();
  }, [navigate, t, dispatch, hideModal, selectedMember]);

  const handleRemove = useCallback(() => {
    if (!localSelectedMember) return;

    const id = localSelectedMember?.id;

    dispatch(setSelectedMember(id));
    dispatch(setRemoveMemberModalVisibility(true));

    if (localSelectedMember.pending) {
      dispatch(rescindInvite(id))
        .unwrap()
        .then(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "info",
              text: t("toastMessages.rescindInviteSuccess"),
            })
          );

          hideModal();
        })
        .catch(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "danger",
              text: t("toastMessages.rescindInviteError"),
            })
          );
        });
    } else {
      dispatch(removeUserFromTeam(id))
        .unwrap()
        .then(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "info",
              text: t("toastMessages.memberRemovedSuccess"),
            })
          );
          initialApiCalls();
          hideModal();
        })
        .catch(() => {
          dispatch(
            addToastMessage({
              id: guid(),
              severity: "danger",
              text: t("toastMessages.removeTeamMemberError"),
            })
          );
        });
    }
  }, [dispatch, localSelectedMember, t, hideModal]);

  useEffect(() => {
    const modalEle = modalRef.current;
    if (modalEle) {
      modalEle.addEventListener("hidden.bs.modal", hiddenModal);
      window.addEventListener("popstate", hideModal);
    }
    if (removeMemberModalVisibility) {
      showModal();
    } else {
      hideModal();
    }

    return () => {
      modalEle && modalEle.removeEventListener("hidden.bs.modal", hiddenModal);
      window.removeEventListener("popstate", hideModal);
    };
  }, [removeMemberModalVisibility, hideModal, hiddenModal, showModal]);

  return removeMemberModalVisibility ? (
    <div
      ref={modalRef}
      className="modal fade"
      id="modal-removeMemberModal"
      tabIndex={-1}
      aria-labelledby="modal-removeMemberModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header ">
            <h6 className="modal-title">
              {localSelectedMember?.pending
                ? t("teams.deleteInviteTitle")
                : t("teams.removeMemberTitle")}
            </h6>
          </div>
          <div className="modal-body">
            <p className="small">
              {localSelectedMember?.pending
                ? t("teams.deleteInviteMessage", {
                    email: localSelectedMember?.email,
                  })
                : t("teams.removeMemberMessage", {
                    email: localSelectedMember?.email,
                  })}
            </p>
          </div>
          <div className="modal-footer px-0">
            <div className="row w-100">
              <div className="col-6">
                {/* Cancel */}
                <button
                  type="button"
                  className="btn btn-secondary btn-sm w-100"
                  data-bs-dismiss="modal"
                >
                  {t("button.cancel")}
                </button>
              </div>
              <div className="col-6">
                {/* Remove */}
                <button
                  id="button-removeMember"
                  type="button"
                  className="btn btn-primary btn-sm w-100"
                  onClick={handleRemove}
                  disabled={removeMemberLoading === "pending"}
                >
                  {removeMemberLoading === "pending" ||
                  rescindInviteLoading === "pending" ? (
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      spin
                      fixedWidth
                    />
                  ) : (
                    <span>
                      {localSelectedMember?.pending
                        ? t("button.delete")
                        : t("button.remove")}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default RemoveRevokeMemberDynamicModal;
