import {
  AccountDetails,
  AccountRequest,
  AccountStates,
  AlterSubscriptionPlan,
  CancelSubscription,
  CustomCheckout,
  DiscountResponse,
  GetAccountDetails,
  GetAvailableDiscount,
  GetAvailablePlans,
  GetOverriddenPlanCheckout,
  GetPaymentPassThroughData,
  PaymentInformation,
  PaymentModificationResult,
  PaymentPlanTypes,
  PaymentReceipt,
  PlanBasicData,
  Profile,
  PurchaseDayPlan,
  ReceiptRequest,
  ReceiptResponse,
  RestartSubscription,
  TeamPlanDetails,
  UserProfile,
} from "./../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "../common/commonTypes";
import { paymentAccountLogic } from "./paymentAccountLogic";

export type LinkName =
  | ""
  | "Buy Plan"
  | "Cancel Subscription"
  | "Restart Subscription"
  | "Switch Plan"
  | "Update Payment Details"
  | "Upgrade Plan"
  | "Upgrade To Business"
  | "Show Plans";

export type Links = {
  linkName: LinkName;
};

export type AccountActualState =
  | "Active Day"
  | "Free"
  | "Active Monthly"
  | "Trial Monthly"
  | "PastDue Monthly"
  | "Delinquent Monthly"
  | "Cancelled Active Monthly"
  | "Cancelled Expired Monthly"
  | "Cancelled Trial"
  | "None";

export type SuccessType = "payment" | "trial" | "";

export type ExtendedAccountDetails = AccountDetails & {
  accountActualState: AccountActualState;
  collapsePlans: boolean;
  currentPlan: PlanBasicData;
  currentPlanDescriptionFirst: string;
  currentPlanDescriptionSecond: string;
  currentPlanLinks: Array<Links>;
  currentPlanShow: boolean;
  marketingContent: string;
};

type PaymentState = {
  account: ExtendedAccountDetails;
  accountErrorMessage: string;
  accountIsLockedMessage: string;
  accountLoading: Loading;
  accountSuspended: boolean;
  alterSubscriptionPlanErrorMessage: string;
  alterSubscriptionPlanLoading: Loading;
  alterSubscriptionPlanResponse: PaymentModificationResult;
  anyActiveSubscriptions: boolean;
  anyPausedSubscriptions: boolean;
  cancelSubscriptionErrorMessage: string;
  cancelSubscriptionLoading: Loading;
  cancelSubscriptionResponse: PaymentModificationResult;
  checkoutSuccessLoader: boolean;
  collapsePlans: boolean;
  currency: string;
  discount: DiscountResponse;
  discountLoading: Loading;
  downForMaintenance: boolean;
  isTrial: boolean;
  noActiveSubscription: boolean;
  overrideCheckout: CustomCheckout;
  overrideCheckoutLoading: Loading;
  overriderCheckoutErrorMessage: string;
  paddleProgress: string;
  passThrough: string;
  passThroughErrorMessage: string;
  passThroughLoading: Loading;
  pauseableSubscriptions: PaymentReceipt[];
  pausedSubscriptions: PaymentReceipt[];
  plans: PlanBasicData[];
  plansErrorMessage: string;
  plansLoading: Loading;
  productAction: LinkName;
  productsLoader: boolean;
  purchaseDayPlanErrorMessage: string;
  purchaseDayPlanLoading: Loading;
  purchaseDayPlanResponse: PaymentModificationResult;
  receipts: ReceiptResponse;
  receiptsErrorMessage: string;
  receiptsLoading: Loading;
  restartPlanErrorMessage: string;
  restartPlanLoading: Loading;
  restartPlanResponse: PaymentModificationResult;
  selectedProduct: any;
  selectedProductId: string;
  subscriptionsIds: Array<number>;
  successType: SuccessType;
  user: UserProfile;
  userErrorMessage: string;
  userIsAwaitingPaymentLessTrial: boolean;
  userLoading: Loading;
};

export const AccountDetailsInitialState: ExtendedAccountDetails = {
  accountActualState: "None",
  accountState: AccountStates.Uninitialized,
  activeUntil: "",
  canUpgradeToPlans: [],
  collapsePlans: true,
  currentPlan: new PlanBasicData(),
  currentPlanDescriptionFirst: "",
  currentPlanDescriptionSecond: "",
  currentPlanLinks: [],
  currentPlanShow: false,
  isActive: false,
  isRecurring: false,
  lastOrderId: "",
  marketingContent: "",
  mnoPlanName: "",
  nextBillingDate: "",
  paymentDetails: new PaymentInformation(),
  paymentProviderPlanId: "",
  paymentProviderSubscriptionId: "",
  paymentProviderUpdatePaymentURL: "",
  planType: PaymentPlanTypes.Unknown,
};

export const UserInitialState = {
  teamPlan: new TeamPlanDetails(),
  profileData: new Profile(),
};

const initialState: PaymentState = {
  account: AccountDetailsInitialState,
  accountErrorMessage: "",
  accountIsLockedMessage: "",
  accountLoading: "idle",
  accountSuspended: false,
  alterSubscriptionPlanErrorMessage: "",
  alterSubscriptionPlanLoading: "idle",
  alterSubscriptionPlanResponse: new PaymentModificationResult(),
  anyActiveSubscriptions: false,
  anyPausedSubscriptions: false,
  cancelSubscriptionErrorMessage: "",
  cancelSubscriptionLoading: "idle",
  cancelSubscriptionResponse: new PaymentModificationResult(),
  checkoutSuccessLoader: false,
  collapsePlans: true,
  currency: "",
  discount: new DiscountResponse(),
  discountLoading: "idle",
  downForMaintenance: false,
  isTrial: false,
  noActiveSubscription: false,
  overrideCheckout: new CustomCheckout(),
  overrideCheckoutLoading: "idle",
  overriderCheckoutErrorMessage: "",
  paddleProgress: "",
  passThrough: "",
  passThroughErrorMessage: "",
  passThroughLoading: "idle",
  pauseableSubscriptions: [],
  pausedSubscriptions: [],
  plans: [],
  plansErrorMessage: "",
  plansLoading: "idle",
  productAction: "",
  productsLoader: false,
  purchaseDayPlanErrorMessage: "",
  purchaseDayPlanLoading: "idle",
  purchaseDayPlanResponse: new PaymentModificationResult(),
  receipts: new ReceiptResponse(),
  receiptsErrorMessage: "",
  receiptsLoading: "idle",
  restartPlanErrorMessage: "",
  restartPlanLoading: "idle",
  restartPlanResponse: new PaymentModificationResult(),
  selectedProduct: null,
  selectedProductId: "",
  subscriptionsIds: [],
  successType: "",
  user: UserInitialState,
  userErrorMessage: "",
  userIsAwaitingPaymentLessTrial: false,
  userLoading: "idle",
};

export const fetchDiscounts = createAsyncThunk(
  "paymentSlice/fetchDiscounts",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetAvailableDiscount())
        .then((discounts) => {
          return discounts;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchAccountRequest = createAsyncThunk(
  "paymentSlice/fetchAccountRequest",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new AccountRequest())
        .then((userData) => {
          return userData;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

//Must happen after fetching plans
export const fetchAccountDetails = createAsyncThunk(
  "paymentSlice/fetchAccountDetails",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetAccountDetails())
        .then((accountData) => {
          //Logic be here

          const account = paymentAccountLogic(accountData);

          thunkAPI.dispatch(setCollapsePlans(account.collapsePlans));
          return account;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchReceipts = createAsyncThunk(
  "paymentSlice/fetchReceipts",
  async ({ skip, take }: Partial<ReceiptRequest> = {}, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient()
      .then(async (client) => {
        return await client
          .get(new ReceiptRequest({ skip, take }))
          .then((ReceiptData) => {
            return ReceiptData;
          })
          .catch((error) => {
            return thunkAPI.rejectWithValue(error);
          });
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchAvailablePlans = createAsyncThunk(
  "paymentSlice/fetchAvailablePlans",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetAvailablePlans())
        .then((Plans) => {
          return Plans;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchPassThrough = createAsyncThunk(
  "paymentSLice/fetchPassThrough",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetPaymentPassThroughData())

        .then((data) => {
          return data.data;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchOverrideCheckout = createAsyncThunk(
  "paymentSLice/fetchOverrideCheckout",
  async (
    { planId, countryCode }: Partial<GetOverriddenPlanCheckout>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new GetOverriddenPlanCheckout({ planId, countryCode }))

        .then((data) => {
          return data;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const restartSubscription = createAsyncThunk(
  "paymentSlice/restartSubscription",
  async ({ subscriptionId }: Partial<RestartSubscription>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new RestartSubscription({ subscriptionId }))
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const purchaseDayPlan = createAsyncThunk(
  "paymentSlice/purchaseDayPlan",
  async ({ subscriptionId }: Partial<PurchaseDayPlan>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new PurchaseDayPlan({ subscriptionId }))
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const alterSubscriptionPlan = createAsyncThunk(
  "paymentSlice/alterSubscriptionPlan",
  async (
    { subscriptionId, planId }: Partial<AlterSubscriptionPlan>,
    thunkAPI
  ) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new AlterSubscriptionPlan({ subscriptionId, planId }))
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const cancelSubscription = createAsyncThunk(
  "paymentSlice/cancelSubscription",
  async ({ subscriptionId }: Partial<CancelSubscription>, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .post(new CancelSubscription({ subscriptionId }))
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

const paymentSlice = createSlice({
  initialState,
  name: "paymentSlice",
  reducers: {
    //
    setSuccessType(state, action: PayloadAction<SuccessType>) {
      state.successType = action.payload;
    },

    setCheckoutSuccessLoader(state, action: PayloadAction<boolean>) {
      state.checkoutSuccessLoader = action.payload;
    },
    setNoActiveSubscription(state, action: PayloadAction<boolean>) {
      state.noActiveSubscription = action.payload;
    },
    setAccountIsLockedMessage(state, action: PayloadAction<string>) {
      state.accountIsLockedMessage = action.payload;
    },
    setAccountSuspended(state, action: PayloadAction<boolean>) {
      state.accountSuspended = action.payload;
    },
    setDownForMaintenance(state, action: PayloadAction<boolean>) {
      state.downForMaintenance = action.payload;
    },
    setSelectedProductId(state, action: PayloadAction<string>) {
      state.selectedProductId = action.payload;
    },

    setProductsLoader(state, action: PayloadAction<boolean>) {
      state.productsLoader = action.payload;
    },

    setProductAction(state, action: PayloadAction<LinkName>) {
      state.productAction = action.payload;
    },

    setCollapsePlans(state, action: PayloadAction<boolean>) {
      state.collapsePlans = action.payload;
    },

    /**
     * Set selected product to be shown when purchasing - populated with data from paddle
     * */
    setSelectedProduct(state, action: PayloadAction<any>) {
      state.selectedProduct = action.payload;
    },
    setPaddleProgress(state, action: PayloadAction<string>) {
      state.paddleProgress = action.payload;
    },
    setTrial(state, action: PayloadAction<boolean>) {
      state.isTrial = action.payload;
    },
    setCurrency(state, action: PayloadAction<string>) {
      state.currency = action.payload;
    },

    setReceipts(state, action: PayloadAction<ReceiptResponse>) {
      state.receipts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // =======================================================================
      //GET: User Profile Data
      .addCase(fetchAccountRequest.pending, (state) => {
        state.userLoading = "pending";
        state.userErrorMessage = "";
      })
      .addCase(fetchAccountRequest.fulfilled, (state, action) => {
        state.user = action.payload as UserProfile;
        state.userLoading = "succeeded";
        state.userErrorMessage = "";
      })
      .addCase(fetchAccountRequest.rejected, (state, rejectedAction) => {
        state.userLoading = "failed";
        state.userErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //GET: User Account Data
      .addCase(fetchAccountDetails.pending, (state) => {
        state.accountLoading = "pending";
        state.accountErrorMessage = "";
      })
      .addCase(fetchAccountDetails.fulfilled, (state, action) => {
        state.account = action.payload;
        state.accountLoading = "succeeded";
        state.accountErrorMessage = "";
      })
      .addCase(fetchAccountDetails.rejected, (state, rejectedAction) => {
        state.accountLoading = "failed";
        state.accountErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //GET: User Receipts Data
      .addCase(fetchReceipts.pending, (state) => {
        state.receiptsLoading = "pending";
        state.receiptsErrorMessage = "";
      })
      .addCase(fetchReceipts.fulfilled, (state, action) => {
        let receipts = action.payload as ReceiptResponse;

        // sort is used in case a newer receipt is added to the list on screen load and receipts already exist in state
        receipts.receipts = [...receipts.receipts].sort((a, b) => {
          return +new Date(b.paidDate ?? 0) - +new Date(a.paidDate ?? 0);
        });

        state.receipts = receipts;
        state.receiptsLoading = "succeeded";
        state.receiptsErrorMessage = "";
      })
      .addCase(fetchReceipts.rejected, (state, rejectedAction) => {
        state.receiptsLoading = "failed";
        state.receiptsErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================

      //GET: Available Plans
      .addCase(fetchAvailablePlans.pending, (state) => {
        state.plansLoading = "pending";
        state.plansErrorMessage = "";
      })
      .addCase(fetchAvailablePlans.fulfilled, (state, action) => {
        //Remove plan type unknown / dev plans

        const filteredPlans = action.payload.plans.filter(
          (plan) =>
            plan.planType.toString() !==
            PaymentPlanTypes[PaymentPlanTypes.Unknown]
        );

        state.plans = filteredPlans;

        state.userIsAwaitingPaymentLessTrial =
          action.payload.userIsAwaitingPaymentlessTrial;

        state.plansLoading = "succeeded";

        state.plansErrorMessage = "";
      })
      .addCase(fetchAvailablePlans.rejected, (state, rejectedAction) => {
        state.plansLoading = "failed";
        state.plansErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //GET: Pass Through
      .addCase(fetchPassThrough.pending, (state) => {
        state.passThroughLoading = "pending";
        state.passThroughErrorMessage = "";
      })
      .addCase(fetchPassThrough.fulfilled, (state, action) => {
        state.passThrough = action.payload as string;
        state.passThroughLoading = "succeeded";
        state.passThroughErrorMessage = "";
      })
      .addCase(fetchPassThrough.rejected, (state, rejectedAction) => {
        state.passThroughLoading = "failed";
        state.passThroughErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //GET: Override Checkout
      .addCase(fetchOverrideCheckout.pending, (state) => {
        state.overrideCheckoutLoading = "pending";
        state.overriderCheckoutErrorMessage = "";
      })
      .addCase(fetchOverrideCheckout.fulfilled, (state, action) => {
        state.overrideCheckout = action.payload as CustomCheckout;
        state.overrideCheckoutLoading = "succeeded";
        state.overriderCheckoutErrorMessage = "";
      })
      .addCase(fetchOverrideCheckout.rejected, (state, rejectedAction) => {
        state.overrideCheckoutLoading = "failed";
        state.overrideCheckout = new CustomCheckout();
        state.overriderCheckoutErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //POST: AlterSubscriptionPlan
      .addCase(alterSubscriptionPlan.pending, (state) => {
        state.alterSubscriptionPlanLoading = "pending";
        state.alterSubscriptionPlanErrorMessage = "";
      })
      .addCase(alterSubscriptionPlan.fulfilled, (state, action) => {
        state.alterSubscriptionPlanLoading = "succeeded";
        state.alterSubscriptionPlanResponse =
          action.payload as PaymentModificationResult;
        state.alterSubscriptionPlanErrorMessage = "";
      })
      .addCase(alterSubscriptionPlan.rejected, (state, rejectedAction) => {
        state.alterSubscriptionPlanLoading = "failed";
        state.alterSubscriptionPlanErrorMessage =
          rejectedAction.payload as string;
      })

      // =======================================================================
      //POST: Restart Plan
      .addCase(restartSubscription.pending, (state) => {
        state.restartPlanLoading = "pending";
        state.restartPlanErrorMessage = "";
      })
      .addCase(restartSubscription.fulfilled, (state, action) => {
        state.restartPlanLoading = "succeeded";
        state.restartPlanResponse = action.payload as PaymentModificationResult;
        state.purchaseDayPlanErrorMessage = "";
      })
      .addCase(restartSubscription.rejected, (state, rejectedAction) => {
        state.restartPlanLoading = "failed";
        state.restartPlanErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //POST: Purchase Day Plan
      .addCase(purchaseDayPlan.pending, (state) => {
        state.purchaseDayPlanLoading = "pending";
        state.purchaseDayPlanErrorMessage = "";
      })
      .addCase(purchaseDayPlan.fulfilled, (state, action) => {
        state.purchaseDayPlanLoading = "succeeded";
        state.purchaseDayPlanResponse =
          action.payload as PaymentModificationResult;
        state.purchaseDayPlanErrorMessage = "";
      })
      .addCase(purchaseDayPlan.rejected, (state, rejectedAction) => {
        state.purchaseDayPlanLoading = "failed";
        state.purchaseDayPlanErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //POST: Cancel Subscription
      .addCase(cancelSubscription.pending, (state) => {
        state.cancelSubscriptionLoading = "pending";
        state.cancelSubscriptionErrorMessage = "";
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.cancelSubscriptionLoading = "succeeded";
        state.cancelSubscriptionResponse =
          action.payload as PaymentModificationResult;
        state.cancelSubscriptionErrorMessage = "";
      })
      .addCase(cancelSubscription.rejected, (state, rejectedAction) => {
        state.cancelSubscriptionLoading = "failed";
        state.cancelSubscriptionErrorMessage = rejectedAction.payload as string;
      })

      // =======================================================================
      //GET: Discounts
      .addCase(fetchDiscounts.pending, (state) => {
        state.discountLoading = "pending";
      })
      .addCase(fetchDiscounts.fulfilled, (state, action) => {
        state.discount = action.payload as DiscountResponse;
        state.discountLoading = "succeeded";
      })
      .addCase(fetchDiscounts.rejected, (state) => {
        state.discountLoading = "failed";
      });
  },
});

export const {
  setAccountIsLockedMessage,
  setAccountSuspended,
  setCheckoutSuccessLoader,
  setCollapsePlans,
  setCurrency,
  setDownForMaintenance,
  setNoActiveSubscription,
  setPaddleProgress,
  setProductAction,
  setProductsLoader,
  setReceipts,
  setSelectedProduct,
  setSelectedProductId,
  setSuccessType,
  setTrial,
} = paymentSlice.actions;

export default paymentSlice.reducer;
