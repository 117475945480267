import {
  AcceptTerms,
  AuthenticationEndPoint,
  AuthenticationUris,
  FetchTermsAndConditions,
  FetchUsersTermsStatus,
  TermsAccepted,
  TermsAndConditionsContent,
  UserTermsStatus,
} from "../../serviceClient/api.dtos";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./../common/commonTypes";
import { RootState } from "./../../app/store";

type LandingState = {
  acceptTermsErrorMessage: string;
  acceptTermsLoading: Loading;
  acceptTermsResponse: TermsAccepted;
  authenticationUris?: AuthenticationUris;
  loading: Loading;
  paymentGatewayStarted: boolean;
  paymentGatewayStartedErrorMessage: string;
  paymentGatewayStartedLoading: Loading;
  termsAndConditions?: TermsAndConditionsContent;
  termsAndConditionsAccepted: boolean;
  termsDeclineModalOpen: boolean;
  userTermsStatus: UserTermsStatus;
};

const initialState: LandingState = {
  acceptTermsErrorMessage: "",
  acceptTermsLoading: "idle",
  acceptTermsResponse: new TermsAccepted(),
  loading: "pending",
  paymentGatewayStarted: false,
  paymentGatewayStartedErrorMessage: "",
  paymentGatewayStartedLoading: "idle",
  termsAndConditionsAccepted: false,
  termsDeclineModalOpen: false,
  userTermsStatus: new UserTermsStatus(),
};

export enum AuthType {
  OAUTH,
  JWT,
}

export const fetchTermsAndConditions = createAsyncThunk(
  "landingSlice/fetchTermsAndConditions",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      return await client
        .get(new FetchTermsAndConditions())
        .then((termsAndConditions: TermsAndConditionsContent) => {
          return termsAndConditions;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchTermsStatus = createAsyncThunk(
  "landingSlice/fetchTermsStatus",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };

    return await getClient().then(async (client) => {
      return await client
        .get(new FetchUsersTermsStatus())
        .then((termsStatus: UserTermsStatus) => {
          return termsStatus;
        })
        .catch(async (error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const acceptTerms = createAsyncThunk(
  "landingSlice/acceptTerms",
  async (_, thunkAPI) => {
    const { getClient } = thunkAPI.extra as {
      getClient(): Promise<JsonServiceClient>;
    };
    return await getClient().then(async (client) => {
      const { landing: landingState } = thunkAPI.getState() as RootState;

      return await client
        .post(
          new AcceptTerms({
            acceptedTime: new Date().toUTCString(),
            id: landingState?.termsAndConditions?.id,
          })
        )
        .then((terms) => {
          return terms;
        })
        .catch((error) => {
          return thunkAPI.rejectWithValue(error);
        });
    });
  }
);

export const fetchAuthenticationUrls = createAsyncThunk(
  "landingSlice/fetchAuthenticationUrls",
  async (_, thunkAPI) => {
    // BearerToken not needed for this call
    const client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

    return await client
      .get(
        new AuthenticationEndPoint({
          clientId: process.env.REACT_APP_CLIENT_ID,
          redirectUri:
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port === "" ? "" : ":" + window.location.port),
        })
      )
      .then((newUris: AuthenticationUris) => {
        return newUris;
      })
      .catch(async (error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const landingSlice = createSlice({
  initialState,
  name: "landingSlice",
  reducers: {
    setTermsDeclineModalOpen(state, action: PayloadAction<boolean>) {
      state.termsDeclineModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // =======================================================================
      // GET: Authentication Urls
      .addCase(fetchAuthenticationUrls.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchAuthenticationUrls.fulfilled, (state, action) => {
        state.authenticationUris = action.payload as AuthenticationUris;
        state.loading = "succeeded";
      })
      .addCase(fetchAuthenticationUrls.rejected, (state) => {
        state.loading = "failed";
      })
      // =======================================================================
      //  GET: Terms Status
      .addCase(fetchTermsStatus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchTermsStatus.fulfilled, (state, action) => {
        state.userTermsStatus = action.payload as UserTermsStatus;
        state.loading = "succeeded";
      })
      .addCase(fetchTermsStatus.rejected, (state) => {
        state.loading = "failed";
      })
      // =======================================================================
      // GET: Terms And Conditions Content
      .addCase(fetchTermsAndConditions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchTermsAndConditions.fulfilled, (state, action) => {
        state.termsAndConditions = action.payload as TermsAndConditionsContent;
        state.loading = "succeeded";
      })
      .addCase(fetchTermsAndConditions.rejected, (state) => {
        state.loading = "failed";
      })

      // =======================================================================
      // POST: Accept Terms
      .addCase(acceptTerms.pending, (state) => {
        state.acceptTermsResponse = initialState.acceptTermsResponse;
        state.acceptTermsLoading = "pending";
        state.acceptTermsErrorMessage = "";
      })
      .addCase(acceptTerms.fulfilled, (state, action) => {
        state.acceptTermsResponse = action.payload as TermsAccepted;
        state.acceptTermsLoading = "succeeded";
        state.acceptTermsErrorMessage = "";
      })
      .addCase(acceptTerms.rejected, (state, rejectedAction) => {
        state.acceptTermsResponse = initialState.acceptTermsResponse;
        state.acceptTermsLoading = "failed";
        state.acceptTermsErrorMessage = rejectedAction.payload as string;
      });
  },
});

export const { setTermsDeclineModalOpen } = landingSlice.actions;

export default landingSlice.reducer;
